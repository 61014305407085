.about__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img{
    height: 440px;
    width: 380px;
    margin-top: -3rem;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__description{
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container{
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }
    
    .about__img{
        height: 280px;
        width: 225px;
        margin-top: 0.1rem;
    }
    
    .about__data{
        text-align: center;
    }
    .about__info{
        justify-content: center;
    }
    .about__description{
        padding: 0 5rem;
        margin-top: -2rem;
        margin-bottom: 2rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 576px) {
    .about__info{
        grid-template-columns: repeat(3,1fr);
    }
    .about__description{
        padding: 0;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .about__info{
        grid-template-columns: repeat(2,1fr);
    }
   
  }